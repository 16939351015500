<template>
  <div id="LoginBox">
    <el-dialog
      class="login"
      :visible.sync="loginDialogBool"
      width="370px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="title" v-if="!showBtn" @click="setActiveItem('showBtn')">
        {{ titlename }}
      </div>

      <el-carousel
        direction="horizontal"
        :autoplay="false"
        indicator-position="none"
        :loop="false"
        arrow="never"
        :height="loginHeight + 'px'"
        ref="login"
      >
        <el-carousel-item name="showBtn">
          <div class="showBtn">
            <div class="logo">
              <img src="@/assets/image/favicon.png" alt="" />
              <div>西语人的专属训练营</div>
            </div>
            <div class="btn">
              <!-- <div>
                <el-button type="primary" @click="setActiveItem('weixinBox')"
                  ><i></i>微信登录</el-button
                >
              </div> -->
              <div>
                <el-button @click="setActiveItem('phoneBox')"
                  >手机号登录</el-button
                >
              </div>
            </div>
            <div class="agreement">
              <el-checkbox name="type" v-model="checkBool"></el-checkbox>
              <span>
                我以阅读并接受
                <a @click="agrenBtn">《西知网用户服务协议》</a>
                <!-- <a>《隐私政策》</a> -->
              </span>
            </div>
          </div></el-carousel-item
        >

        <el-carousel-item name="weixinBox">
          <div class="weixinBox">
            <!-- 微信 -->
            <wxlogin
              :appid="objWx.appid_pc"
              :scope="objWx.scope_pc"
              :redirect_uri="objWx.redirect_uri_pc"
              :state="objWx.state"
              :href="objWx.href"
              :secret="objWx.secret_pc"
            ></wxlogin>
            <div>请使用微信扫描二维码登录</div>
            <div class="modeShua" v-show="showModel">
              <div>二维码失效</div>
              <el-button size="mini" round @click="clickReload">刷新</el-button>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item name="phoneBox">
          <div class="phoneBox">
            <!-- 手机号 -->
            <div class="phone">
              <span>+86</span>
              <input v-model="phone" placeholder="请输入手机号" type="text" />
            </div>
            <el-button
              :disabled="phone == '' ? true : false"
              type="primary"
              @click="clickGetCode"
              >获取动态码</el-button
            >
            <div class="weixin" @click="setActiveItem('weixinBox')">
              微信登录
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item name="codeBox">
          <div class="codeBox">
            <!-- 验证码 -->
            <div class="code_bg"></div>
            <div>
              短信验证码已发送至<span>{{ phone }}</span>
            </div>
            <input v-model="code" placeholder="短信验证码" type="text" />
            <el-button
              :disabled="code == '' ? true : false"
              type="primary"
              @click="clickGetCodeSend"
              >提交</el-button
            >
          </div>
        </el-carousel-item>
        <!-- 上传头像 -->
        <el-carousel-item name="avaterBox">
          <div class="avaterBox">
            <div class="msg">
              <h2>欢迎来到西知网</h2>
              <p>补充个人信息，开始学习之旅吧</p>
              <van-uploader
                :after-read="afterRead"
                :max-count="1"
                :accept="'image/*'"
              >
                <van-button>更换头像</van-button>
                <img :src="fileList[0].url" alt="" />
              </van-uploader>
            </div>
            <div class="username">
              <div>
                <div><img src="@/assets/image/usericon.png" alt="" /></div>
                <div>用户名</div>
              </div>
              <el-input
                v-model="username"
                placeholder="请输入用户名"
              ></el-input>
            </div>
            <el-button
              :disabled="username == '' ? true : false"
              type="primary"
              @click="confirmBtn"
              >完成</el-button
            >
          </div>
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>

<script>
import wxlogin from "vue-wxlogin";
import axios from "axios";
export default {
  inject: ["reload"],
  data() {
    return {
      loginDialogBool: false, //登录
      phone: "", //手机号
      code: "", //验证码
      titlename: "",
      loginHeight: 450, //轮播图高度
      showModel: false, //刷新二维码
      username: "", //用户名
      fileList: [{ url: require("../assets/image/avatar.png") }],
      imgUrl: "", //头像
      showBtn: true, //展示按钮
      checkBool: true, //是否同意
      showBtn: true, //展示按钮
      objWx: {
        appid: "",
        scope: "snsapi_login",
        redirect_uri: "http%3a%2f%2ftk.spanishknow.com%2f%23%2fwxLogin",
        state: "", //随机数
        style: "black",
        href: "",
      },
    };
  },
  components: {
    wxlogin,
  },
  methods: {
    setActiveItem(val) {
      // 切换登录
      let _this = this;
      if (!_this.checkBool) {
        _this.$message.error("请勾选同意《西知网用户服务协议》、《隐私政策》");
      } else {
        _this.$refs.login.setActiveItem(val);
        _this.showBtn = false;
        if (val == "weixinBox") {
          _this.loginHeight = 400;
          _this.titlename = "";
        } else if (val == "phoneBox") {
          _this.loginHeight = 200;
          _this.titlename = "登录/注册";
        } else {
          _this.loginHeight = 450;
          _this.showBtn = true;
          _this.titlename = "";
        }
      }
    },
    clickReload() {
      // 刷新二维码
      let _this = this;
      _this.weixin_login();
      _this.showModel = false;
      setTimeout(() => {
        _this.showModel = true;
      }, 60000);
    },
    clickGetCode() {
      // 获取验证码登录
      let _this = this;
      if (_this.phone.length != 11) {
        _this.$message.error("手机号码有误，请重填");
      } else {
        let obj = {
          tel: _this.phone,
        };
        _this.$api.easySms(obj).then((res) => {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          _this.$refs.login.setActiveItem("codeBox");
          _this.loginHeight = 400;
          _this.titlename = "填写动态码";
        });
      }
    },
    clickGetCodeSend() {
      // 提交登录
      let _this = this;
      // _this.$refs.login.setActiveItem("avaterBox");

      let obj = {
        tel: _this.phone,
        code: _this.code,
        username: _this.phone,
        password: _this.phone,
      };
      _this.$api.resgiter(obj).then((res) => {
        localStorage.userInfo = JSON.stringify(res.data);
        // 1秒跳转
        _this.$api.check().then((res) => {
          if (res.data.state == 0) {
            _this.reload();
            _this.loginDialogBool = false;
          } else {
            _this.$refs.login.setActiveItem("avaterBox");
            _this.loginHeight = 400;
            _this.titlename = "";
          }
        });
      });
    },
    //验证
    confirmBtn() {
      //手机号登陆
      let _this = this;
      if (_this.username == "") {
        this.$toast.fail("用户名不能为空");
      } else {
        let obj = {
          avatar: _this.imgUrl,
          username: _this.username,
          name: _this.username,
        };
        _this.$api.editme(obj).then((res) => {
          // 提示信息
          // this.$toast.success("登录成功");
          // 1秒跳转
          _this.reload();
          _this.loginDialogBool = false;
        });
      }
    },
    agrenBtn() {
      this.centerDialogVisible = true; //弹框显示
    },
    clicksignOut() {
      let _this = this;
      if (this.$route.path != "/Index/Gateway") {
        _this.$router.push("/");
      }
      localStorage.clear();
      this.$router.go(0);
    },
    handleClose() {
      let _this = this;
      _this.$refs.login.setActiveItem("showBtn");
      _this.loginHeight = 450;
      _this.showBtn = true;
      _this.titlename = "";
      _this.loginDialogBool = false;
    },
    weixin_login() {
      let _this = this;
      let obj = {
        token: "680b4f91afb709iDAeiRSfb4mFsq5J2hFvuoUMKYf",
      };
      _this.$api.getwxinfo(obj).then((res) => {
        _this.objWx = res.data;
        _this.objWx.style = "black";
        _this.objWx.redirect_uri_pc =
          "http%3a%2f%2ftk.spanishknow.com%2f%23%2fwxLogin";
        _this.objWx.href =
          "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDE4MHB4O21hcmlnbi10b3A6LThweH0KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge2Rpc3BsYXk6IG5vbmU7fQ==";
        setTimeout(() => {
          _this.showModel = true;
        }, 60000);
      });
    },
    // 头像上传方法
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      let _this = this;
      let obj = {
        filetype: "avatar",
      };
      _this.$api.avatarInfo(obj).then((res) => {
        // console.log(res.data);
        _this.imgUrl = res.data.filepath;
        let formdata = new FormData();
        formdata.append("OSSAccessKeyId", res.data.access_id);
        formdata.append("policy", res.data.policy);
        formdata.append("signature", res.data.signature);
        formdata.append("key", res.data.filepath);
        formdata.append("file", file.file);
        axios.post(res.data.url, formdata).then((res1) => {
          _this.fileList[0].url = res.data.path;
        });
      });
    },
    clickloginDialogBool() {
      //弹框
      let _this = this;
      _this.loginDialogBool = true;
      _this.weixin_login();
    },
  },
};
</script>

<style lang="less" scoped>
@bgcolor: #df3833;
#LoginBox {
  // width: 100%;
  // height: 100%;
  .login {
    // 登录框
    /deep/ .el-dialog {
      // height: 480px;
      border-radius: 8px;
      // /deep/.el-carousel__container{
      //   height: 400px;
      // }
      .el-dialog__body {
        background: #fff;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
      .el-dialog__header {
        background: #fff;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      .el-dialog__headerbtn {
        top: 16px;
      }
      .showBtn {
        height: 400px;
        .logo {
          text-align: center;
          margin-bottom: 40px;
          img {
            width: 60px;
            height: 60px;
            margin-bottom: 20px;
          }
          div {
            font-size: 20px;
          }
        }
      }
      .avaterBox {
        .van-uploader {
          position: relative;
          z-index: 9999;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          overflow: hidden;
          margin-bottom: 25px;
          img {
            width: 80px;
            height: 80px;
          }
          /deep/.van-uploader__input {
            z-index: 9999;
          }
          button {
            position: absolute;
            z-index: 999;
            width: 100%;
            height: 30px;
            font-size: 12px;
            background-color: rgba(0, 0, 0, 0.5);
            bottom: -18px;
            border-radius: 0;
            color: #fff;
          }
        }
        .avatar-uploader {
          width: 80px;
          height: 80px;
          margin: 0 auto;
          position: relative;
          border-radius: 50%;
          overflow: hidden;
          button {
            position: absolute;
            z-index: 999;
            width: 100%;
            height: 26px;
            line-height: 26px;
            font-size: 12px;
            background-color: rgba(0, 0, 0, 0.5);
            bottom: 0px;
            padding: 0;
            border-radius: 0;
            color: #fff;
            left: 0;
            margin: 0;
          }
        }

        i {
          font-size: 18px;
        }
        .msg {
          text-align: center;
          h2 {
            height: 30px;
            font-size: 22px;

            color: #333;
            line-height: 30px;
            margin: 0;
          }
          p {
            height: 22px;
            font-size: 16px;

            color: #999;
            line-height: 22px;
            margin: 6px 0 30px;
          }
        }
        .username {
          width: 100%;
          height: 44px;
          border: 1px solid #ccc;
          border-radius: 20px;
          margin: 20px 0px 0px;
          display: flex;
          box-sizing: border-box;
          > div {
            &:first-child {
              display: flex;
              align-items: center;
              margin-left: 20px;

              img {
                width: 18px;
                margin-right: 5px;
              }
            }
          }
          /deep/.el-input {
            width: 200px;
            height: 44px !important;
            border: none;
            .el-input__inner {
              border: none;
              height: 42px;
              padding-top: 2px;
            }
          }
        }
        button {
          font-size: 14px;
          box-sizing: border-box;
          border-radius: 40px;
          width: 100%;
          margin-left: 2px;
          border-radius: 40px;
          margin-bottom: 20px;
          height: 44px;
          margin-top: 20px;
          // box-shadow: 0 2px 8px 0 rgba(47, 90, 255, 0.35);
        }
        .el-button--primary.is-disabled,
        .el-button--primary.is-disabled:active,
        .el-button--primary.is-disabled:focus,
        .el-button--primary.is-disabled:hover {
          background: rgba(199, 0, 11, 0.3);
          border: rgba(199, 0, 11, 0.3);
        }
        .el-button--primary {
          background: @bgcolor;
          border: @bgcolor;
        }
      }
      .title {
        // width: 20px;
        height: 20px;
        background: url(../assets/image/left.png) 0 0 no-repeat;
        background-size: 20px 20px;
        position: absolute;
        top: -0px;
        left: 20px;
        cursor: pointer;
        padding-left: 20px;
      }

      .btn {
        margin-left: 5px;
        button {
          width: 320px;
          border-radius: 40px;
          margin-bottom: 20px;
          height: 50px;
          background: @bgcolor;
          border: @bgcolor;
          color: #fff;
          i {
            margin-right: 10px;
            width: 30px;
            height: 30px;
            display: inline-block;
            margin-top: -3px;
            vertical-align: middle;
            background: url(../assets/image/weixin.svg);
            background-size: 30px 30px;
            background-repeat: no-repeat;
            background-position: 0 0;
          }
        }
        // > div {
        //   &:nth-child(2) {
        //     button {
        //       color: #000;
        //       background: #fbe7e7;
        //       border: none;
        //     }
        //   }
        // }
      }
      .agreement {
        padding: 0 15px 20px 15px;
        font-size: 12px;
        .el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          background: #df3833;
          border: #df3833;
        }
        input {
          vertical-align: sub;
        }
        span {
          color: #666;
          a {
            color: #df3833;
            cursor: pointer;
          }
        }
      }
      .weixinBox {
        > div {
          padding: 60px 0;
          padding-bottom: 20px;
          text-align: center;
          font-size: 18px;
        }
        /deep/ iframe {
          width: 200px;
          height: 200px;
        }
        .modeShua {
          position: absolute;
          left: 74px;
          z-index: 200;
          top: 76px;
          background: rgba(0, 0, 0, 0.5);
          width: 181px;
          height: 181px;
          padding-top: 20px;
          color: #fff;
          padding-top: 50px;
          div {
            margin-bottom: 10px;
          }
          button {
            width: 50px;
            height: 30px;
            border-radius: 5px;
            &:hover {
              color: @bgcolor;
            }
          }
        }
      }
      .phoneBox {
        text-align: center;
        .phone {
          border-radius: 40px;
          width: 320px;
          margin: 20px 0;
          height: 42px;
          border: 1px solid #ccc;
          display: flex;
          align-items: center;
          font-size: 14px;
          padding-left: 18px;
          margin-left: 4px;
          input {
            font-size: 14px;
            box-sizing: border-box;
            color: #333;
            margin: 20px 0;
            height: 100%;
            padding-left: 10px;
            border: none;
          }
        }
        .weixin {
          cursor: pointer;
          &:hover {
            color: @bgcolor;
          }
        }

        button {
          font-size: 14px;
          box-sizing: border-box;
          border-radius: 40px;
          width: 320px;
          border-radius: 40px;
          margin-bottom: 20px;
          height: 50px;
          // box-shadow: 0 2px 8px 0 rgba(47, 90, 255, 0.35);
        }
        .el-button--primary.is-disabled,
        .el-button--primary.is-disabled:active,
        .el-button--primary.is-disabled:focus,
        .el-button--primary.is-disabled:hover {
          background: rgba(199, 0, 11, 0.3);
          border: rgba(199, 0, 11, 0.3);
        }
        .el-button--primary {
          background: @bgcolor;
          border: @bgcolor;
        }
      }
      .codeBox {
        text-align: center;
        .code_bg {
          width: 300px;
          height: 150px;
          background: url(../assets/image/code.svg) no-repeat 85px;
        }
        > div {
          span {
            color: @bgcolor;
          }
        }
        input {
          border-radius: 40px;
          width: 300px;
          margin: 20px 0;
          height: 42px;
          border: 1px solid #ccc;
          display: flex;
          align-items: center;
          font-size: 14px;
          padding-left: 40px;
          margin-left: 15px;
          background: url(../assets/image/codeD.png) no-repeat 10px 10px;
          background-size: 20px 20px;
        }

        button {
          font-size: 14px;
          box-sizing: border-box;
          border-radius: 40px;
          width: 300px;
          margin-left: 2px;
          border-radius: 40px;
          margin-bottom: 20px;
          height: 50px;
          // box-shadow: 0 2px 8px 0 rgba(47, 90, 255, 0.35);
        }
        .el-button--primary.is-disabled,
        .el-button--primary.is-disabled:active,
        .el-button--primary.is-disabled:focus,
        .el-button--primary.is-disabled:hover {
          background: rgba(199, 0, 11, 0.3);
          border: rgba(199, 0, 11, 0.3);
        }
        .el-button--primary {
          background: @bgcolor;
          border: @bgcolor;
        }
      }
    }
  }
}
</style>